import React, {useState} from 'react';
import moment from 'moment';
import TimeSlot from './timeSlot';

export default function VerticalLineSchedule({
  schedule = [],
  searchFilters,
  setDocСalendarDate,
}) {
  

  if (schedule.length === 0) return <li className="schedule-time-list__item empty" />;
  if(searchFilters?.onlyAvailable && schedule.status !== 'FREE') return <li className="schedule-time-list__item empty" />;
  // Get time format for display 
  const getTimeRangeText = () => {
    
    const firstSlotTime = moment(schedule.time_from).format('HH:mm');
    const lastSlotTime = moment(schedule.time_to).format('HH:mm');
    
    return (<span className='timeRangeText'>{firstSlotTime} <i></i> {lastSlotTime}</span>);
  };

  return (
    <>
      <div className={`schedule-time-list__item schedule-time-list__title more ${schedule.status === 'PAST' ? 'past' : ''} ${schedule.status === 'ABSENT' ? 'past' : ''} ${schedule.is_booked_by_patient === true ? 'booked_by_patient' : ''}`} onClick={() => { setDocСalendarDate(moment(schedule.time_from).format('YYYY-MM-DD')) }}>
        <span className="time-range">{ getTimeRangeText() }</span>
      </div>
    
    </>
  );
}
