import React, { useMemo } from 'react';
import moment from 'moment';

import { useDoctorScheduleApi, useDoctorsJobsPageListApi } from './api';
import Doctor from './common/Doctor';
import Pagination from '../../components/global/List/Pagination';

export default function DoctorsPageListComponent({
  doctorSchedule,
  city,
  calendarDate,
  setCalendarDate,
  isVaccinates,
  placeholder,
  isOperator,
  disableOwnLink = false,
  websiteCovidSource = null,
  searchFilters,
  setSearchFilters,
  getDoctorsJobs,
  doctorsJobs,
  patients,
  user,
}) {
  
  useDoctorsJobsPageListApi({
    doctorsJobs,
    getDoctorsJobs,
    city,
    isVaccinates,
    searchFilters
  });

  // Updated to use the doctor-specific loading states
  const { doctorLoadingStates, providerSchedules, providerDaySchedules } = useDoctorScheduleApi({
    city,
    isVaccinates,
    searchFilters,
    doctorsJobs,
  });

  const renderDoctorJob = (job) => {
    const providerId = job.provider_id || 'default';
    
    // Get provider-specific schedule data with safety checks
    const providerScheduleData = providerSchedules[providerId] || [];
    const providerDayScheduleData = providerDaySchedules[providerId] || [];
    
    // Use doctor-specific loading state with safety check
    const isLoading = job?.doctor?.id ? (doctorLoadingStates[job.doctor.id] !== false) : true;
    
    // Find the doctor's schedule in the provider data
    const fullDoctorScheduleSlots = job?.doctor?.id ? 
      providerScheduleData.find(item => item && item.id === job.doctor.id) : null;
    
    // Create job-specific schedule by filtering slots that match this job ID
    const jobDoctorScheduleSlots = fullDoctorScheduleSlots ? {
      ...fullDoctorScheduleSlots,
      schedule: Array.isArray(fullDoctorScheduleSlots.schedule) 
        ? fullDoctorScheduleSlots.schedule.filter(slot => slot && slot.job_id === job.id)
        : []
    } : null;

    // Find calendar days data from provider-specific data only (not global data anymore)
    const fullDoctorScheduleDays = job?.doctor?.id ? 
      providerDayScheduleData.find(item => item && item.id === job.doctor.id) : null;
    
    // Create job-specific calendar days by filtering days that match this job ID
    const jobDoctorScheduleDays = fullDoctorScheduleDays ? {
      ...fullDoctorScheduleDays,
      schedule: Array.isArray(fullDoctorScheduleDays.schedule)
        ? fullDoctorScheduleDays.schedule.filter(slot => slot && slot.job_id === job.id)
        : []
    } : null;
    
    return (<Doctor
      websiteCovidSource={websiteCovidSource}
      isOperator={isOperator}
      isVaccinates={isVaccinates}
      key={`${job.doctor.id}-${job.id}`}
      full_name={job.doctor.full_name}
      job={job}
      indicators={job.doctor.indicators}
      specializations={job.doctor.specializations}
      doctorSchedule={doctorSchedule}
      jobDoctorScheduleDays={jobDoctorScheduleDays}
      jobDoctorScheduleSlots={jobDoctorScheduleSlots}
      loading={isLoading}
      photo={job.doctor.photo}
      doctor={job.doctor}
      city={city}
      disableOwnLink={disableOwnLink}
      setCalendarDate={setCalendarDate}
      calendarDate={calendarDate}
      searchFilters={searchFilters}
      setSearchFilters={setSearchFilters}
      patients={patients?.data}
      user={user}
    />)
  }

  // Group doctors by specialization
  const groupedDoctors = useMemo(() => {
    let jobsToDisplay = [...doctorsJobs.data];
    
    // Filter by availability on the selected date when both filters are active
    if (searchFilters?.visit_date && searchFilters?.onlyAvailable) {
      const selectedDate = moment(searchFilters.visit_date).format('YYYY-MM-DD');
      
      // Filter doctors who have slots available on the selected day
      jobsToDisplay = jobsToDisplay.filter(job => {
        const providerId = job.provider_id || 'default';
        const providerDayScheduleData = providerDaySchedules[providerId] || [];
        
        // Find schedule for this doctor in our local state (not redux)
        const doctorScheduleData = providerDayScheduleData.find(item => item && item.id === job.doctor.id);
        
        // If no schedule or empty schedule, filter out
        if (!doctorScheduleData || !doctorScheduleData.schedule || doctorScheduleData.schedule.length === 0) {
          return false;
        }
        
        // Check if there are slots for this job on the selected date
        return doctorScheduleData.schedule.some(slot => {
          const slotDate = moment(slot.time_from).format('YYYY-MM-DD');
          return slotDate === selectedDate && slot.job_id === job.id && slot.status === 'FREE';
        });
      });
    }
    
    // Group by specialization as before
    const groups = {};
    jobsToDisplay.forEach(job => {
      const specializationName = job.position?.name || 'Інші спеціалісти';
      if (!groups[specializationName]) {
        groups[specializationName] = [];
      }
      groups[specializationName].push(job);
    });
    
    return {
      groups,
      filteredCount: jobsToDisplay.length
    };
  }, [doctorsJobs.data, providerDaySchedules, searchFilters?.visit_date, searchFilters?.onlyAvailable]);

  if (doctorsJobs.loaded && Object.entries(groupedDoctors.groups).length === 0) {
    return placeholder || <div className="empty-list">Нічого не знайдено</div>;
  }
  const getPlaceWordForm = (count) => {
    const lastDigit = count % 10;
    const lastTwoDigits = count % 100;
    
    // Special case for teens (11-19)
    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
      return 'місць';
    }
    
    if (lastDigit === 1) {
      return 'місце';
    } else if (lastDigit >= 2 && lastDigit <= 4) {
      return 'місця';
    } else {
      return 'місць';
    }
  };

  // Calculate the actual displayed count
  const displayCount = (searchFilters?.visit_date && searchFilters?.onlyAvailable) 
    ? groupedDoctors.filteredCount 
    : doctorsJobs.pagination.total;

  return (
      <>
        {(doctorsJobs.loaded  &&
        <div className='search-result-count'>
          <span>Знайдено: {displayCount} {getPlaceWordForm(displayCount)} надання послуг</span>
        </div>
        )}
        {Object.entries(groupedDoctors.groups).map(([specializationName, jobs]) => (
          <div key={specializationName}>
            <h3 className="specialization-title">{specializationName}</h3>
            <div className="specialization-group">
              {jobs.map(job => renderDoctorJob(job))}
            </div>
          </div>
        ))}
        <Pagination
          currentPage={doctorsJobs.currentPage}
          pageName="doctors_page"
          totalPages={doctorsJobs.pagination.total_pages}
        />
      </>
    );
}