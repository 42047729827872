import { useCallback, useEffect, useRef, useState } from 'react';
import { getDoctorSchedule as apiGetDoctorSchedule, getDoctorsByDaysSchedule as apiGetDoctorsByDaysSchedule } from '../../../api';

import { useUrlParser } from '../../../utils/useUrlParser';

export function useDoctorsJobsPageListApi({
  doctorsJobs,
  getDoctorsJobs,
  city,
  isVaccinates = false,
  searchFilters = null,
}) {
  
  const urlParser = useUrlParser();
  
  const prevFiltersRef = useRef({
    name: searchFilters?.name,
    specialization_id: searchFilters?.specialization_id,
    institution_id: searchFilters?.institution_id,
    doctor_id: searchFilters?.doctor_id,
    byReferral: searchFilters.byReferral,
    onlyAvailable: searchFilters.onlyAvailable,
    paidConsultations: searchFilters.paidConsultations,
    doctors_page: urlParser.urlData.params.doctors_page
  });

  const fetchJobs = useCallback(() => {
    
    getDoctorsJobs({
      cityId: city.id,
      page:
        +urlParser.urlData.params.doctors_page > 0 && Number.isInteger(+urlParser.urlData.params.doctors_page)
          ? +urlParser.urlData.params.doctors_page
          : 1,
      name: searchFilters.name,
      specializationId: searchFilters.specialization_id,
      institutionId: searchFilters.institution_id,
      doctorId: searchFilters.doctor_id,
      byReferral: searchFilters.byReferral,
      onlyAvailable: searchFilters.onlyAvailable,
      paidConsultations: searchFilters.paidConsultations,
      pageSize: 30,
      isVaccinates,
    });
  }, [
    city.id,
    getDoctorsJobs,
    isVaccinates,
    searchFilters,
    urlParser.urlData.params.doctors_page,
  ]);

  // Effect to handle initial data loading and search filters changes
  useEffect(() => {
    
    if (city?.id) {
      const shouldFetch = 
        prevFiltersRef.current.name !== searchFilters.name ||
        prevFiltersRef.current.specialization_id !== searchFilters.specialization_id ||
        prevFiltersRef.current.institution_id !== searchFilters.institution_id ||
        prevFiltersRef.current.doctor_id !== searchFilters.doctor_id ||
        prevFiltersRef.current.byReferral !== searchFilters.byReferral ||
        prevFiltersRef.current.onlyAvailable !== searchFilters.onlyAvailable ||
        prevFiltersRef.current.paidConsultations !== searchFilters.paidConsultations ||
        prevFiltersRef.current.doctors_page !== urlParser.urlData.params.doctors_page;

      // Додаємо індикатор першого завантаження
      const isInitialLoad = !doctorsJobs.loaded && (doctorsJobs.data === undefined || doctorsJobs.data.length === 0);
      
      // Викликаємо запит якщо змінилися фільтри або це перше завантаження
      if (shouldFetch || isInitialLoad) {
        fetchJobs();
        
        // Оновлюємо збережені значення
        prevFiltersRef.current = {
          name: searchFilters.name,
          specialization_id: searchFilters.specialization_id,
          institution_id: searchFilters.institution_id,
          doctor_id: searchFilters.doctor_id,
          byReferral: searchFilters.byReferral,
          onlyAvailable: searchFilters.onlyAvailable,
          paidConsultations: searchFilters.paidConsultations,
          doctors_page: urlParser.urlData.params.doctors_page
        };
      }
    }
  }, [
    city, 
    searchFilters,
    urlParser.urlData.params.doctors_page,
  ]);

};

export function useDoctorScheduleApi({
  city,
  isVaccinates,
  doctorsJobs,
  searchFilters,
}) {
  // Track loading state per doctor instead of per provider for more granular control
  const [doctorLoadingStates, setDoctorLoadingStates] = useState({});
  // Store provider schedule data
  const [providerSchedules, setProviderSchedules] = useState({});
  const [providerDaySchedules, setProviderDaySchedules] = useState({});
  // Track which providers have already loaded their day schedules
  const [loadedProviderDays, setLoadedProviderDays] = useState({});
  
  // Track the previous list of doctor IDs to detect changes
  const prevDoctorIdsRef = useRef([]);
  
  // Configuration variables for optimization
  const BATCH_SIZE = 3; // Number of doctor IDs per request
  const BATCH_DELAY = 300; // Delay between batches in milliseconds
  
  // Helper function to split array into batches
  const splitIntoBatches = (array, batchSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += batchSize) {
      result.push(array.slice(i, i + batchSize));
    }
    return result;
  };
  
  // Effect to reset schedules when doctorJobs.data changes
  useEffect(() => {
    // Extract doctor IDs from the current jobs
    const currentDoctorIds = doctorsJobs.data.map(job => job.doctor.id).sort().join(',');
    const prevDoctorIds = prevDoctorIdsRef.current.join(',');
    
    // If the doctor IDs have changed, reset everything
    if (currentDoctorIds !== prevDoctorIds) {
      // Reset all states
      setProviderSchedules({});
      setProviderDaySchedules({});
      setLoadedProviderDays({});
      setDoctorLoadingStates({});
      
      // Update the reference
      prevDoctorIdsRef.current = doctorsJobs.data.map(job => job.doctor.id).sort();
    }
  }, [doctorsJobs.data]);
  
  // Group doctors by provider and fetch schedules
  useEffect(() => {
    if (!doctorsJobs.data.length) return;
    
    // Initialize all doctors as loading
    const initialLoadingStates = {};
    doctorsJobs.data.forEach(job => {
      initialLoadingStates[job.doctor.id] = true;
    });
    setDoctorLoadingStates(initialLoadingStates);
    
    // Group doctors by provider_id
    const doctorsByProvider = {};
    doctorsJobs.data.forEach(job => {
      const providerId = job.provider_id || 'default';
      if (!doctorsByProvider[providerId]) {
        doctorsByProvider[providerId] = [];
      }
      doctorsByProvider[providerId].push(job.doctor.id);
    });
    
    // Store all provider batch results for proper merging
    const providerBatchResults = {};
    
    let batchCounter = 0;
    
    // Process each provider's doctors
    Object.entries(doctorsByProvider).forEach(([providerId, doctorIds]) => {
      // Initialize provider results storage
      if (!providerBatchResults[providerId]) {
        providerBatchResults[providerId] = {
          scheduleSlots: {},  // Use object for de-duplication by doctor ID
          scheduleDays: {}    // Use object for de-duplication by doctor ID
        };
      }
      
      // Split doctor IDs into batches
      const batches = splitIntoBatches(doctorIds, BATCH_SIZE);
      
      // Process each batch with a staggered delay (first batch is immediate)
      batches.forEach((batchIds, batchIndex) => {
        var ids = batchIds.join(',');
        
        // First batch for each provider loads immediately, others are staggered
        const delay = batchIndex === 0 ? 0 : (++batchCounter * BATCH_DELAY);
        
        // Fetch the daily slots if visit_date is specified
        if (searchFilters?.visit_date) {
          const dates = {
            date_from: searchFilters.visit_date,
            date_to: searchFilters.visit_date,
          };
          const providerDaysLoaded = loadedProviderDays[providerId];
          if (providerDaysLoaded && providerDaySchedules[providerId]) {
            // Filter out doctors that already have empty schedules or don't have free slots for the selected date
            const filteredBatchIds = batchIds.filter(batchId => {
              const doctorData = providerDaySchedules[providerId].find(doctor => doctor.id === batchId);
              
              // Check if doctor has valid schedule data
              if (!doctorData || !doctorData.schedule || doctorData.schedule.length === 0) {
                return false;
              }
              
              // Check if doctor has at least one FREE slot on the selected date
              return doctorData.schedule.some(slot => {
                // Extract only the date part from time_from (format: YYYY-MM-DD HH:MM:SS)
                const slotDate = slot.time_from ? slot.time_from.split(' ')[0] : null;
                // Check if the slot is for the selected date and has FREE status
                return slotDate === searchFilters.visit_date && slot.status === 'FREE';
              });
            });
            
            setDoctorLoadingStates(prev => {
              const updates = {};
              batchIds.forEach(id => {
                if(!filteredBatchIds.includes(id)) {
                  updates[id] = false;
                }
              });
              return { ...prev, ...updates };
            });
            
            if(filteredBatchIds.length === 0) {
              return;
            }
            ids = filteredBatchIds.join(',');

          }

          setTimeout(() => {
            apiGetDoctorSchedule({ ...dates, ids, isVaccinates }, { disableLoader: true })
              .then(response => {
                // Add error handling for missing or malformed response
                const responseData = response && response.data ? response.data : [];
                
                // Process each doctor's data separately and merge properly
                responseData.forEach(doctorData => {
                  if (doctorData && doctorData.id) {
                    providerBatchResults[providerId].scheduleSlots[doctorData.id] = doctorData;
                  }
                });
                
                // Convert from object to array and update state
                const allDoctorSchedules = Object.values(providerBatchResults[providerId].scheduleSlots);
                setProviderSchedules(prev => ({
                  ...prev,
                  [providerId]: allDoctorSchedules
                }));
                
                // Mark these specific doctors as loaded
                setDoctorLoadingStates(prev => {
                  const updates = {};
                  batchIds.forEach(id => {
                    updates[id] = false;
                  });
                  return { ...prev, ...updates };
                });
              })
              .catch((error) => {
                console.error("Failed to fetch doctor schedule:", error);
                
                // Mark doctors as loaded even if there's an error
                setDoctorLoadingStates(prev => {
                  const updates = {};
                  batchIds.forEach(id => {
                    updates[id] = false;
                  });
                  return { ...prev, ...updates };
                });
              });
          }, delay);
        }
        
        // Fetch the calendar days availability - but only if we haven't loaded it yet for this provider
        if (!loadedProviderDays[providerId]) {
          const today = new Date();
          const thirtyDaysLater = new Date();
          thirtyDaysLater.setDate(today.getDate() + 30);
          
          const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
          };
          
          const dates = {
            date_from: formatDate(today),
            date_to: formatDate(thirtyDaysLater),
          };
          
          setTimeout(() => {
            apiGetDoctorsByDaysSchedule({ ...dates, ids }, { disableLoader: true })
              .then(response => {
                // Add error handling for missing or malformed response
                const responseData = response && response.data ? response.data : [];
                
                // Process each doctor's data separately and merge properly
                responseData.forEach(doctorData => {
                  if (doctorData && doctorData.id) {
                    providerBatchResults[providerId].scheduleDays[doctorData.id] = doctorData;
                  }
                });
                
                // Convert from object to array and update state
                const allDoctorDays = Object.values(providerBatchResults[providerId].scheduleDays);
                setProviderDaySchedules(prev => ({
                  ...prev,
                  [providerId]: allDoctorDays
                }));
                
                // If no visit_date specified, mark these doctors as loaded after days schedule is fetched
                if (!searchFilters?.visit_date) {
                  setDoctorLoadingStates(prev => {
                    const updates = {};
                    batchIds.forEach(id => {
                      updates[id] = false;
                    });
                    return { ...prev, ...updates };
                  });
                }
                
                // Mark this provider as having loaded its day schedule data
                if (batchIndex === batches.length - 1) {
                  setLoadedProviderDays(prev => ({
                    ...prev,
                    [providerId]: true
                  }));
                }
              })
              .catch(error => {
                console.error("Failed to fetch days schedule:", error);
                
                // If no visit_date specified, mark these doctors as loaded even if there's an error
                if (!searchFilters?.visit_date) {
                  setDoctorLoadingStates(prev => {
                    const updates = {};
                    batchIds.forEach(id => {
                      updates[id] = false;
                    });
                    return { ...prev, ...updates };
                  });
                }
                
                // Mark this provider as attempted even on error
                if (batchIndex === batches.length - 1) {
                  setLoadedProviderDays(prev => ({
                    ...prev,
                    [providerId]: true
                  }));
                }
              });
          }, delay);
        } else {
          // If we already have day schedule data for this provider and no visit_date,
          // mark doctors as loaded immediately
          if (!searchFilters?.visit_date) {
            setDoctorLoadingStates(prev => {
              const updates = {};
              batchIds.forEach(id => {
                updates[id] = false;
              });
              return { ...prev, ...updates };
            });
          }
        }
      });
    });
    
  }, [doctorsJobs.data, city, isVaccinates, searchFilters?.visit_date]);
  
  // Reset loaded days tracking when city changes
  useEffect(() => {
    if (city?.id) {
      setLoadedProviderDays({});
    }
  }, [city?.id]);
  
  // Return the doctor-specific loading states and provider data
  return {
    doctorLoadingStates,
    providerSchedules,
    providerDaySchedules
  };
}

export function usePatientsApi({ getPatients, token }) {
  useEffect(() => {
    if (token) getPatients();
  }, [getPatients, token]);
}
