import React, { useCallback, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { 
  moveDoctorScheduleDateRangeLeft, 
  moveDoctorScheduleDateRangeRight,
} from '../../../../../actions/doctors';
import { Date } from './date';
import { TheEndSchedulePopup } from './popup';
import { months } from '../../../../../constants';

function ScheduleHeader({
  doctorSchedule,
  moveDoctorScheduleDateRangeLeft,
  moveDoctorScheduleDateRangeRight,

  calendarDate,
  setCalendarDate,
  isMobile = false,
  searchFilters,
}) {

  const theEndSchedulePopupRef = useRef();

  const clickNext = useCallback(() => {
    if (theEndSchedulePopupRef.current) theEndSchedulePopupRef.current.clickNext();

    moveDoctorScheduleDateRangeRight();
  }, [moveDoctorScheduleDateRangeRight]);

  const handleResetDate = useCallback((e) => {
    e.stopPropagation();
    setCalendarDate("");
  }, [setCalendarDate]);

  useEffect(() => {
    if (searchFilters?.visit_date) {
      const isDateInRange = doctorSchedule.datesRange.includes(searchFilters.visit_date);
      if (!isDateInRange) {
        const targetDate = moment(searchFilters.visit_date);
        const firstDateInRange = moment(doctorSchedule.datesRange[0]);
        const lastDateInRange = moment(doctorSchedule.datesRange[doctorSchedule.datesRange.length - 1]);
        
        // Check if targetDate is before or after current range
        if (targetDate.isBefore(firstDateInRange)) {
          // Calculate days between and shift left that many times
          const daysDiff = firstDateInRange.diff(targetDate, 'days');
          // Use setTimeout to ensure state updates between shifts
          const shiftLeft = (remaining) => {
            if (remaining <= 0) return;
            
            moveDoctorScheduleDateRangeLeft();
            
            setTimeout(() => {
              shiftLeft(remaining - 1);
            }, 0);
          };
          
          shiftLeft(daysDiff);
        } else if (targetDate.isAfter(lastDateInRange)) {
          // Calculate days between and shift right that many times
          const daysDiff = targetDate.diff(lastDateInRange, 'days');
          
          const shiftRight = (remaining) => {
            if (remaining <= 0) return;
            
            moveDoctorScheduleDateRangeRight();
            
            setTimeout(() => {
              shiftRight(remaining - 1);
            }, 0);
          };
          
          shiftRight(daysDiff);
        }
      }
    }
  }, [searchFilters?.visit_date, doctorSchedule.datesRange, moveDoctorScheduleDateRangeLeft, moveDoctorScheduleDateRangeRight]);

  return (
    <>
      <div className={`schedule-wrapper${isMobile ? ' mobile' : ''}`}>
        <div className="schedule">
          <div className="schedule-header">
            <div
              className="schedule-navigation schedule-navigation__prev"
              onClick={ moveDoctorScheduleDateRangeLeft}
            >
              <i className="fas fa-chevron-left" />
            </div>
            <ul className={`schedule-date-list${searchFilters?.visit_date ? ' has_active_visit_date' : ' not_has_active_visit_date'}`}>
              {(doctorSchedule.datesRange).map((date) => (
                <li
                  key={date}
                  className={`schedule-date-list__item${date === calendarDate ? ' active' : ''}${searchFilters?.visit_date && date === searchFilters?.visit_date ? ' active_visit_date' : ''}`}
                  onClick={() => setCalendarDate(date)}
                >
                  <div className="schedule-date-title">{months[moment(date).format('MMMM')].short}.</div>
                  <Date date={date} isMobile={isMobile} />
                  {searchFilters?.visit_date && date === searchFilters?.visit_date && (
                    <div className="date-reset-icon" onClick={handleResetDate}>
                      <i className="fas fa-times" />
                    </div>
                  )}
                </li>
              ))}

              <TheEndSchedulePopup
                datesRange={doctorSchedule.datesRange}
                doctorSchedule={doctorSchedule}
                popupOutsideRef={theEndSchedulePopupRef}
              />
            </ul>
            <div className="schedule-navigation schedule-navigation__next" onClick={clickNext}>
              <i className="fas fa-chevron-right" />
            </div>
          </div>
        </div>
      </div>
      <style>{`
        .active_visit_date {
          background-color: #e7ffbf;
        }
        .has_active_visit_date .schedule-date-list__item:not(.active_visit_date) .schedule-date-description.today{
          font-weight: normal;
          color: #979797;
        }
        
        .active_visit_date span.schedule-date-title, .not_has_active_visit_date span.schedule-date-title {
          color:#000 !important;
        }

        .schedule-date-list__item {
          cursor: pointer;
        }
        .date-reset-icon {
          position: absolute;
          top: 2px;
          right: 2px;
          cursor: pointer;
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba(0,0,0,0.1);
          border-radius: 50%;
        }
        .date-reset-icon:hover {
          background-color: rgba(0,0,0,0.2);
        }
        .schedule-date-list__item {
          position: relative;
        }
      `}</style>
    </>
  );
}

const mapDispatchToProps = {
  moveDoctorScheduleDateRangeLeft,
  moveDoctorScheduleDateRangeRight,
};

const mapStateToProps = ({ doctorSchedule }) => ({
  doctorSchedule,
});

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleHeader);
