import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export function useDoctorPageApi({ getDoctor, doctor, propDoctorId }) {
  const params = useParams();
  const doctorId = propDoctorId || params?.doctorId;
  
  useEffect(() => {
    if(doctorId && !doctor?.loading) {
      getDoctor(doctorId);  
    }
  }, [doctorId]);
}

export function useDoctorScheduleApi({ doctor, getDoctorSchedule, doctorSchedule, getDoctorsByDaysSchedule, doctorsByDaysSchedule, city, propDoctorId }) {
  useEffect(() => {
    if (doctor.data && city?.settings?.show_map) {
      const data = {
        date_from: doctorSchedule.initialDateFrom,
        date_to: doctorSchedule.initialDateTo,
        ids: doctor.data.id,
      };
      
      if(!propDoctorId || propDoctorId === doctor?.data.id) {
        if(doctorSchedule?.data.length === 0) {
          getDoctorSchedule(data);
        }

        if(doctorsByDaysSchedule?.data.length === 0) {
          getDoctorsByDaysSchedule(data);
        }
      }

    }
  }, [
    getDoctorSchedule,
    doctor?.data,
    doctorSchedule?.initialDateFrom,
    doctorSchedule?.initialDateTo,
    getDoctorsByDaysSchedule,
    city,
  ]);
}
