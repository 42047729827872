import * as TYPES from '../constants/types';

export const serverErrorInterceptor = ({ dispatch }) => (next) => (action) => {
  if (action.type === TYPES.LOGIN_FAILURE) {
    return next(action);
  }

  if (action.payload && (action.payload.status >= 400 || action.payload.error?.status >= 400)) {
    console.log('Error payload:', JSON.stringify(action.payload));
    
    let errorMessage = '';
    let statusCode = null;
    
    // Special case for the specific structure from the log
    if (action.payload.error && action.payload.error.code && Array.isArray(action.payload.error.code)) {
      console.log('Processing error with code array');
      errorMessage = action.payload.error.code.join('; ');
      statusCode = action.payload.status;
    }
    // Check if payload itself is the error object with message and errors fields
    else if (action.payload.message && action.payload.errors) {
      console.log('Processing direct payload with errors field');
      statusCode = action.payload.status_code || action.payload.status;
      errorMessage = action.payload.message;
      
      // Process nested errors if they exist
      if (action.payload.errors && typeof action.payload.errors === 'object') {
        const errorMessages = [];
        Object.entries(action.payload.errors).forEach(([field, msgs]) => {
          if (Array.isArray(msgs)) {
            msgs.forEach(msg => errorMessages.push(msg));
          } else if (typeof msgs === 'string') {
            errorMessages.push(msgs);
          }
        });
        
        if (errorMessages.length > 0) {
          errorMessage += ': ' + errorMessages.join('; ');
        }
      }
    } 
    // Check if the error is within payload.error
    else if (action.payload.error) {
      console.log('Processing payload.error object');
      if (typeof action.payload.error === 'object') {
        // Handle nested error object
        if (action.payload.error.message && action.payload.error.errors) {
          errorMessage = action.payload.error.message;
          statusCode = action.payload.error.status_code || action.payload.error.status;
          
          if (action.payload.error.errors && typeof action.payload.error.errors === 'object') {
            const errorMessages = [];
            Object.entries(action.payload.error.errors).forEach(([field, msgs]) => {
              if (Array.isArray(msgs)) {
                msgs.forEach(msg => errorMessages.push(msg));
              } else if (typeof msgs === 'string') {
                errorMessages.push(msgs);
              }
            });
            
            if (errorMessages.length > 0) {
              errorMessage += ': ' + errorMessages.join('; ');
            }
          }
        } else {
          // Fallback for other object structure
          if (typeof action.payload.error === 'object' && !action.payload.error.error && !action.payload.error.message) {
            // Extract error messages from any property that contains an array of strings
            const errorMessages = [];
            Object.entries(action.payload.error).forEach(([field, value]) => {
              if (Array.isArray(value)) {
                value.forEach(msg => errorMessages.push(msg));
              } else if (typeof value === 'string') {
                errorMessages.push(value);
              }
            });
            
            if (errorMessages.length > 0) {
              errorMessage = errorMessages.join('; ');
            } else {
              errorMessage = JSON.stringify(action.payload.error);
            }
          } else {
            errorMessage = action.payload.error.error || action.payload.error.message || JSON.stringify(action.payload.error);
          }
          statusCode = action.payload.error.status || action.payload.status;
        }
      } else if (typeof action.payload.error === 'string') {
        errorMessage = action.payload.error;
        statusCode = action.payload.status;
      }
    } 
    // Fallback for direct payload
    else {
      console.log('Using direct payload as error');
      errorMessage = action.payload.message || JSON.stringify(action.payload);
      statusCode = action.payload.status;
    }

    console.log('Processed error message:', errorMessage);
    console.log('Status code:', statusCode);

    dispatch({
      type: TYPES.SHOW_ALERT_MESSAGE,
      payload: { 
        message: errorMessage || 'An unknown error occurred', 
        statusCode: statusCode 
      },
    });
  }

  return next(action);
};
