import React, {useState} from 'react';
import { Col, Row } from 'react-bootstrap';

import defaultMedicSvg from '../../../../assets/img/next/svg/medic.svg';

import RatingBar from '../../../components/global/List/RatingBar';
import Photo from '../../../list/doctorsPageList/common/Doctor/photo';
import Appointment from '../../../../containers/Appointment';

// import AppointmentsCalendarModal from '../../../listv2/doctorsPageList/common/Doctor/appointmentsCalendarModal';

import DoctorCalendar from './DoctorCalendar';

import Description from './description';
import Indicators from './indicators';
import Education from './education';
import useDoctorApi from './api';
import Jobs from './jobs';
import { useUrlParser } from '../../../../utils/useUrlParser';

export default function Doctor({
  description,
  doctor,
  full_name,
  indicators,
  jobs,
  photo,
  rating,
  currentDoctorSchedule,
  byDaysSchedule,
  user,
  city,
  patients,
  isModal = false,
  currentDate = null,
  currentjobId = null,
  jobDoctorScheduleDays = null,
}) {
  
  const {
    timeSlot,
    setTimeSlot,
    // isVisibleAppointmentsCalendar,
    // setIsVisibleAppointmentsCalendar,
    isDirect,
  } = useDoctorApi({ jobs });

  const urlParser = useUrlParser();
  
  const [selectedJobId, setSelectedJobId] = useState(parseInt(urlParser.urlData.params?.job_id) || currentjobId || (jobs[0]?.id));
  const [selectedDate, setSelectedDate] = useState(urlParser.urlData.params?.calendar_date || currentDate || null);
  
  const handleJobSelect = (jobId) => {
    setSelectedJobId(jobId);

    const newUrl = new URL(window.location);
    if (parseInt(newUrl.searchParams.get('job_id')) !== jobId) {
      newUrl.searchParams.set('job_id', jobId);
      newUrl.searchParams.delete('calendar_date');
      window.history.replaceState({}, '', newUrl);
    }
    
  };

  return (
    <div className={isModal ? '' : "medic-feature"}>
      <Appointment doctor={doctor} timeSlot={timeSlot} isOpen={Boolean(timeSlot)} onClose={() => setTimeSlot(null)} showSearchBtn={true} jobDoctorScheduleDays={jobDoctorScheduleDays} />
      <Row>
        <Col xs={{ span: 12 }} xl={{ span: user.city?.settings?.allow_timeslots ? 6 : 12 }}>
          <div className="medic-feature-profile-wrapper">
            <div className="medic-feature-profile">
              <div className="medic-feature-profile-status">
                <Photo photo={photo} defaultPhoto={defaultMedicSvg} />
                <RatingBar rating={rating} indicators={indicators} full_name={full_name} />
              </div>
              <div className="medic-feature-profile-information">
                <div className="medic-feature-profile-information__title">{full_name}</div>
                <Jobs jobs={jobs} doctor={doctor} city={city} setSelectedJobId={handleJobSelect} selectedJobId={selectedJobId}/>
                {jobs.length > 1 && (<p className='jobs-more-text'><i>*</i>Звертаємо Вашу увагу, що лікар має декілька спеціальностей та/або місць роботи. Виберіть, необхідну Вам позицію.</p>)}
              </div>
            </div>

            <div className="medic-feature-profile-detail">
              <ul className="medic-feature-profile-detail-list">
                <Indicators indicators={indicators} />
                <Description description={description} />
                <Education show={false} />
              </ul>
            </div>
          </div>
        </Col>
        {!!city?.settings?.allow_timeslots && (
          <Col xs={{ span: 12 }} xl={{ span: 6 }}>
            <div className="medic-feature-schedule-wrapper">
              <div className="schedule-wrapper">
                <div className="schedule-title-wrapper">
                  <div className="schedule-title">Розклад</div>
                  {/* {currentDoctorScheduleExists && (
                    <CalendarButton showAppointmentsCalendar={() => setIsVisibleAppointmentsCalendar(true)} />
                  )} */}
                </div>

                <DoctorCalendar 
                  onOpenAppointment={setTimeSlot}
                  currentDoctorSchedule={currentDoctorSchedule}
                  byDaysSchedule={byDaysSchedule}
                  selectedJobId={selectedJobId}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  isDirect={isDirect}
                  city={city}
                  patients={patients}
                />
              </div>
            </div>
          </Col>
        )}
      </Row>
      {/* <AppointmentsCalendarModal
        doctor={doctor}
        city={city}
        byDaysSchedule={byDaysSchedule}
        show={isVisibleAppointmentsCalendar}
        onClose={() => setIsVisibleAppointmentsCalendar(false)}
      /> */}
    </div>
  );
}

