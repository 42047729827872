import { LOCATION_CHANGE } from 'react-router-redux';
import moment from 'moment';

import * as TYPES from '../../constants/types';
import { SCHEDULE_DAYS_COUNT } from '../../constants/config';
import { range } from '../../utils/dateRange';

const datesRangeCount = () => {
  if (window.innerWidth >= 1200) return SCHEDULE_DAYS_COUNT;
  if (window.innerWidth >= 910 && window.innerWidth < 1000) return 9;
  if (window.innerWidth >= 810 && window.innerWidth < 910) return 8;
  if (window.innerWidth >= 710 && window.innerWidth < 810) return 7;
  if (window.innerWidth >= 600 && window.innerWidth < 710) return 6;
  if (window.innerWidth >= 510 && window.innerWidth < 600) return 5;
  if (window.innerWidth >= 395 && window.innerWidth < 510) return 4;
  // if (window.innerWidth < 395) return 3;

  return 3;
};

const getLocalDate = () => localStorage.getItem('datetime') ?? undefined;

const initialState = () => ({
  data: [],
  loading: false,
  loaded: false,
  error: null,
  date: moment(getLocalDate()).format('YYYY-MM-DD'),
  initialDateFrom: moment(getLocalDate()).format('YYYY-MM-DD'),
  initialDateTo: moment(getLocalDate()).add(4, 'w').format('YYYY-MM-DD'),
  datesRange: range(
    moment(getLocalDate()).format('YYYY-MM-DD'),
    moment(getLocalDate()).add(datesRangeCount(), 'd').format('YYYY-MM-DD'),
  ),
});

export default function (state = initialState(), action) {
  switch (action.type) {
    case TYPES.GET_SETTINGS_SUCCESS: {
      const { datetime } = action.payload;

      return {
        ...state,
        date: moment(datetime).format('YYYY-MM-DD'),
        initialDateFrom: moment(datetime).format('YYYY-MM-DD'),
        initialDateTo: moment(datetime).add(4, 'w').format('YYYY-MM-DD'),
        datesRange: range(
          moment(datetime).format('YYYY-MM-DD'),
          moment(datetime).add(datesRangeCount(), 'd').format('YYYY-MM-DD'),
        ),
      };
    }

    case TYPES.GET_DOCTOR_SCHEDULE_REQUEST: {
      const currentDate = moment(localStorage.getItem('datetime') ?? undefined).format('YYYY-MM-DD');

      if (currentDate !== state.date) {
        const datesRange = range(currentDate, moment(currentDate).add(datesRangeCount(), 'd').format('YYYY-MM-DD'));

        return {
          ...state,
          datesRange,
          date: currentDate,
          loading: true,
          loaded: false,
        };
      }

      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case TYPES.GET_DOCTOR_SCHEDULE_SUCCESS: {
      const newIds = action.payload.data.map((item) => item.id);
      const data = action.payload.replace
        ? [...state.data.filter((item) => !newIds.includes(item.id)), ...action.payload.data]
        : action.payload.data;

      return {
        ...state,
        data,
        loading: false,
        loaded: true,
      };
    }

    case TYPES.SET_SCHEDULE_DATE:
      return { ...state, date: action.payload };

    case TYPES.MOVE_DOCTOR_SCHEDULE_DATES_RANGE_LEFT: {
      const { datesRange, initialDateFrom } = state;

      if (datesRange[0] === initialDateFrom) {
        return state;
      }

      return {
        ...state,
        datesRange: [
          moment(datesRange[0]).subtract(1, 'day').format('YYYY-MM-DD'),
          ...datesRange.slice(0, datesRange.length - 1),
        ],
      };
    }

    case TYPES.MOVE_DOCTOR_SCHEDULE_DATES_RANGE_RIGHT: {
      const { datesRange, initialDateTo } = state;

      if (datesRange[datesRange.length - 1] === initialDateTo) {
        return state;
      }

      return {
        ...state,
        datesRange: [
          ...datesRange.slice(1, datesRange.length),
          moment(datesRange[datesRange.length - 1])
            .add(1, 'day')
            .format('YYYY-MM-DD'),
        ],
      };
    }

    case TYPES.DOCTOR_SCHEDULE_DATES_RANGE_RESIZE: {
      const { dateRangeSize } = action.payload;

      const datesRange =
        state.datesRange.length > dateRangeSize
          ? state.datesRange.slice(0, dateRangeSize)
          : range(state.datesRange[0], moment(state.datesRange[0]).add(dateRangeSize, 'd').format('YYYY-MM-DD'));

      return {
        ...state,
        datesRange,
      };
    }

    case TYPES.GET_DOCTOR_SCHEDULE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.payload.error,
      };

    case LOCATION_CHANGE:
      return initialState();
    
    case 'RESET_DOCTOR_SCHEDULES': {
      const newState = initialState();
      return {
        ...newState,
        date: state.date,
        datesRange: state.datesRange,
      };
    }

    default:
      return { ...state };
  }
}
