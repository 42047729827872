import React from 'react';
import LoaderGif from '../../../../../../../assets/img/next/gif/loader.gif';

export default function EmptySchedule({ placeholder, showLoader = false }) {
  return (
    <div className="schedule-wrapper">
      <div className="schedule">
        <div className="schedule-body">
          <div className="schedule-body__placeholder">{showLoader && (<img width={30} src={LoaderGif} alt="Loading..." />)} {placeholder ?? null}</div>
        </div>
      </div>
    </div>
  );
}
