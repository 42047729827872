import React, { useState, useEffect } from 'react';
import { Container, Tab } from 'react-bootstrap';
import { connect } from 'react-redux';

import {
  getDoctors,
  getDoctorSchedule,
  getDoctorsJobs,
  getDoctorsMapData,
  getDoctorMapData,
  setCalendarDate,
  getDoctorsByDaysSchedule,
  doctorScheduleDateRangeResize,
} from '../../actions/doctors';

import { fetchPatients } from '../../actions/patientsActions';
import { syncBot, updateCity } from '../../actions';

import Sticky from 'react-sticky-el';

import HeaderSearchList from '../components/global/header/indexSearchList';
import Footer from '../components/global/footer';

import DoctorsPageList from './doctorsPageList';

import useDoctorScheduleDateRangeResize from '../../hooks/useDoctorScheduleDateRangeResize';

import { useSetCity } from '../../hooks/useSetCity';

import ScheduleHeader from '../components/global/List/ScheduleHeaderListv2';
import SearchFormV2 from '../components/global/searchFormV2';

import { useUrlParser } from '../../utils/useUrlParser';
import { useHistory } from "react-router-dom";
import { usePatientsApi } from './doctorsPageList/api';
import LoaderGif from '../../assets/img/next/gif/loader.gif';

const LayoutList = ({
  doctor,
  doctors,
  doctorSchedule,
  getDoctors,
  getDoctorSchedule,
  getDoctorsByDaysSchedule,
  getDoctorsJobs,
  doctorsByDaysSchedule,
  getPatients,
  user,
  syncBot,
  updateCity,
  doctorScheduleDateRangeResize,
  doctorsJobs,
  patients
}) => {
  
  usePatientsApi({ getPatients, token: user.token });

  useDoctorScheduleDateRangeResize({ datesRange: doctorSchedule.datesRange, dateRangeSize: 10, doctorScheduleDateRangeResize });
  
  const { city } = useSetCity({ user, updateCity });
  
  const urlParser = useUrlParser();
  const history = useHistory();
  
  const transformParams = (params) => {
    if (!params) return {};
    
    const transformedParams = { ...params };
    
    Object.keys(transformedParams).forEach(key => {
      if (transformedParams[key] === 'true') {
        transformedParams[key] = true;
      } else if (transformedParams[key] === 'false') {
        transformedParams[key] = false;
      }
    });
    
    return transformedParams;
  };
  
  const [searchFilters, setSearchFilters] = useState(transformParams(urlParser.urlData.params));

  useEffect(() => {
    // Extract city ID from URL if user.city.id is not available
    const extractCityIdFromUrl = () => {
      const pathMatch = window.location.pathname.match(/\/city\/(\d+)/);
      return pathMatch ? pathMatch[1] : null;
    };
    
    // Use user.city.id if available, otherwise try to get it from the URL
    const cityId = user?.city?.id || extractCityIdFromUrl() || 4; // Fallback to 4 if nothing found
    
    const baseUrl = `/city/${cityId}/list`;
    
    // Create query string from search parameters
    const queryParams = new URLSearchParams();
    
    // Add search params to query string
    if (searchFilters?.institution_id) queryParams.set('institution_id', searchFilters?.institution_id);
    if (searchFilters?.specialization_id) queryParams.set('specialization_id', searchFilters.specialization_id);
    if (searchFilters?.doctor_id) queryParams.set('doctor_id', searchFilters.doctor_id);
    // if (searchFilters?.visit_date) queryParams.set('visit_date', searchFilters.visit_date);
    if (searchFilters?.byReferral) queryParams.set('byReferral', true);
    if (searchFilters?.paidConsultations) queryParams.set('paidConsultations', true);
    if (searchFilters?.onlyAvailable) queryParams.set('onlyAvailable', true);
    
    // Combine base URL and query parameters
    const newUrl = `${baseUrl}${queryParams.toString() !== '' ? `?${queryParams.toString()}` : ''}`;
    // Get current URL path + query string
    const currentUrl = `${window.location.pathname}${window.location.search}`;
    
    // Only push to history if the URL has actually changed
    
    if (currentUrl !== newUrl) {
      history.replace(newUrl);
    }
  }, [searchFilters]);
  
  // Wrapper function that updates searchFilters when calendar date changes
  const handleSetCalendarDate = (date) => {
    setSearchFilters(prevFilters => ({
      ...prevFilters,
      visit_date: date
    }));
  }

  // Add state to track mobile status
  const [isMobile, setIsMobile] = useState(window.innerWidth < 550);
  
  // Add state to track search form visibility, collapsed by default on mobile
  const [showSearchForm, setShowSearchForm] = useState(!isMobile);
  
  // Handle window resize to update mobile state
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 550;
      setIsMobile(mobile);
    };
    
    // Add event listener
    window.addEventListener('resize', handleResize);
    
    // Clean up
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  

  // Toggle function for search form visibility
  const toggleSearchForm = () => {
    setShowSearchForm(prev => !prev);
    
    // Reinitialize Sticky component after toggling
    setTimeout(() => {
      // Force sticky component to recalculate its position using window resize event
      window.dispatchEvent(new Event('resize'));
    }, 350); // Wait for transition to complete
  };

  return (
    <>
    <div>
      {/* {doctorSchedule.loading && (
        <div className="loading-overlay">
          <div className="loading-message">
            <p>По вашому запиту система опитує графіки роботи лікарів.</p>
            <p>Для прискорення пошуку, деталізуйте запит.</p>
            <img width={80} src={LoaderGif} alt="Loading..." />
            <p>Будь ласка, зачекайте...</p>
          </div>
        </div>
      )} */}
      <HeaderSearchList city={user?.city} />
      <main>
        <div className="data-list-wrapper schedule-listv2">
          <Container>
            <div className="tabs-wrapper">
              <Tab.Container activeKey={ "doctors" } onSelect={ "doctors" }>
                <Sticky className="sticky-wrapper">
                  <div className={`search-form-container ${showSearchForm ? 'expanded' : 'collapsed'}`}>
                    <SearchFormV2 
                      searchFilters={searchFilters} 
                      setSearchFilters={setSearchFilters}
                    />
                  </div>
                  {isMobile && (
                    <div className="search-form-toggle">
                      <button 
                        onClick={toggleSearchForm}
                        className="btn btn-primary"
                        aria-label={showSearchForm ? 'Hide search form' : 'Show search form'}
                      >
                        {showSearchForm ? '▲ Згорнути фільтри' : '▼ Розгорнути фільтри'}
                      </button>
                    </div>
                  )}
                  <div className="navigation">
                    <ScheduleHeader searchFilters={searchFilters} setCalendarDate={handleSetCalendarDate} calendarDate={searchFilters?.visit_date} />                    
                  </div>
                </Sticky>
                <Tab.Content>
                  <Tab.Pane eventKey={ "doctors" }>
                    <DoctorsPageList
                      doctor={doctor}
                      doctors={doctors}
                      doctorSchedule={doctorSchedule}
                      doctorsByDaysSchedule={doctorsByDaysSchedule}
                      getDoctors={getDoctors}
                      getDoctorsJobs={getDoctorsJobs}
                      doctorsJobs={doctorsJobs}
                      getDoctorSchedule={getDoctorSchedule}
                      getDoctorsByDaysSchedule={getDoctorsByDaysSchedule}
                      searchFilters={searchFilters}
                      setSearchFilters={setSearchFilters}
                      user={user}
                      city={city}
                      syncBot={syncBot}
                      setCalendarDate={handleSetCalendarDate}
                      patients={patients}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Container>
        </div>
      </main>
      <Footer />
    </div>
    <style>{`
      .navigation {
        justify-content: end;
      }      
      
      .search-form-container.collapsed {
        max-height: 0;
        opacity: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
        visibility: hidden; /* Add visibility hidden for better accessibility */
      }
      
      .search-form-container.expanded {
        max-height: 800px; /* Increased to allow more space */
        opacity: 1;
        margin-bottom: 1rem;
        visibility: visible;
      }
      
      .search-form-toggle {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
      }
      
      .loading-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(165, 165, 165, 0.46);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
      }
      
      .loading-message {
        text-align: center;
        background-color: rgb(255, 255, 255);
        padding: 2rem;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        max-width: 90%;
        font-size: 1.25rem;
        color:#094fa3;
      }
      .loading-message p {
        margin: 0;
      }
      .loading-message img {
        padding: 10px 0;
      }
    `}</style>
    </>
  );
};

const mapStateToProps = ({
  doctor,
  doctors,
  doctorsMap,
  doctorSchedule,
  doctorsByDaysSchedule,
  divisions,
  divisionsMap,
  user,
  doctorsJobs,
  patients, // Add patients from the Redux store
}) => ({
  doctor,
  doctors,
  doctorsMap,
  doctorSchedule,
  doctorsByDaysSchedule,
  divisions,
  divisionsMap,
  user,
  doctorsJobs,
  patients, // Pass patients to the component
});

const mapDispatchToProps = {
  getDoctors,
  getDoctorsMapData,
  getDoctorMapData,
  getDoctorSchedule,
  getDoctorsByDaysSchedule,
  setCalendarDate,
  getPatients: fetchPatients,
  syncBot,
  updateCity,
  doctorScheduleDateRangeResize,
  getDoctorsJobs,
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutList);
