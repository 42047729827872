import React, {useState} from 'react';
import { NavLink } from 'react-router-dom';

import defaultMedicSvg from '../../../../../assets/img/next/svg/medic.svg';

import Photo from './photo';

import DesktopSchedule from './schedule/DesktopSchedule';
import useDoctorApi from '../../../../doctorPage/common/Doctor/api';
import Appointment from '../../../../../containers/Appointment';
import { Modal } from 'react-bootstrap';
import DoctorPage from '../../../../../containers/DoctorPage';

import { useDispatch } from 'react-redux';

export default function Doctor({
  doctorSchedule,
  doctor,
  full_name,
  job,
  photo,
  jobDoctorScheduleDays,
  jobDoctorScheduleSlots,
  searchFilters,
  setSearchFilters,
  city,
  isVaccinates = false,
  isOperator = false,
  disableOwnLink = false,
  websiteCovidSource = null,
  patients,
  loading,
  user,
}) {
  
  const dispatch = useDispatch();

  const {
    timeSlot,
    setTimeSlot,
    isDirect,
  } = useDoctorApi({ jobs: job ? [job] : [] }); // Pass job as an array for compatibility
 
  const [showDoctorModal, setShowDoctorModal] = useState(false);
 
  const [docСalendarDate, setDocСalendarDateState] = useState(null);
  
  // Custom function that updates date state and opens the modal
  const setDocСalendarDate = (date) => {
    setDocСalendarDateState(date);
    handleLinkClick();
  };

  const handleLinkClick = (e) => {
    if(e) e.preventDefault();
    if(doctor.id !== doctorSchedule?.data[0]?.id) {
      dispatch({ type: 'RESET_DOCTOR_SCHEDULES' });
      dispatch({ type: 'RESET_DOCTORS_BY_DAYS_SCHEDULES' });
    }
    setShowDoctorModal(true);
  };
  
  return (
    <div className="feature-card__item">
      <Appointment
        isOperator={isOperator}
        isVaccinates={isVaccinates}
        websiteCovidSource={websiteCovidSource}
        doctor={doctor}
        job={job}
        timeSlot={timeSlot}
        isOpen={!!timeSlot}
        onClose={() => setTimeSlot(null)}
        jobDoctorScheduleDays={jobDoctorScheduleDays}
      />

      {showDoctorModal && (
        <Modal style={{ zIndex: 1030 }} backdropClassName="custom-backdrop" show={showDoctorModal} onHide={() => setShowDoctorModal(false)} centered className="doctor-modal">
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DoctorPage
              doctorId={doctor.id}
              currentjobId={job?.id}
              currentDate={docСalendarDate}
              user={user}
              patients={patients ? { data: patients } : undefined}
              isModal={true}
              onClose={() => setShowDoctorModal(false)}
              jobDoctorScheduleDays={jobDoctorScheduleDays}
            />
          </Modal.Body>
        </Modal>
      )}

      <div className="medic-feature-profile">
        <div className="medic-feature-profile-status">
          <Photo photo={photo} defaultPhoto={defaultMedicSvg} />
        </div>

        <div className="medic-feature-profile-information">
          <div className="medic-feature-profile-information__title">
            <NavLink
              to={`/city/${city?.id}/doctors/${doctor.id}?job_id=${job?.id}${searchFilters?.visit_date ? `&calendar_date=${searchFilters.visit_date}` : ''}`}
              disabled={disableOwnLink}
              onClick={handleLinkClick}
              className={`${disableOwnLink ? 'disabled' : ''}`}
            >
              {full_name}
            </NavLink>
          </div>
          {Boolean(city?.settings?.allow_timeslots) && job && !searchFilters?.institution_id && (
              <div className="job-location-title">
                  <span 
                    onClick={() => {
                      setSearchFilters((prev) => ({...prev, institution_id: job.institution.id}));
                    }}
                  >
                    {job.institution.name}
                  </span>
              </div>
          )}
        </div>
      </div>

      {Boolean(city?.settings?.allow_timeslots) && (
            <DesktopSchedule
              key={`${doctor.id}-${job.id}`}
              doctor={doctor}
              job={job}
              onOpenAppointment={setTimeSlot}
              
              datesRange={doctorSchedule.datesRange}
              jobDoctorScheduleSlots={jobDoctorScheduleSlots}
              jobDoctorScheduleDays={jobDoctorScheduleDays}
              loading={loading}
              
              isDirect={isDirect}
              setDocСalendarDate={setDocСalendarDate}
              searchFilters={searchFilters}
              patients={patients}
            />
      )}
    </div>
  );
}
